import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Experiences from "../../containers/experience/Experiences.js";
import "./Experience.css";
import { Fade } from "react-reveal";
import "../../RubberBand.css";
import $ from "jquery";

class Experience extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div className="experience-main">
        <Header />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: "#ea5455" }}
                >
                  <span class="rubberBand">E</span>
                  <span class="rubberBand">x</span>
                  <span class="rubberBand">p</span>
                  <span class="rubberBand">e</span>
                  <span class="rubberBand">r</span>
                  <span class="rubberBand">i</span>
                  <span class="rubberBand">e</span>
                  <span class="rubberBand">n</span>
                  <span class="rubberBand">c</span>
                  <span class="rubberBand">e</span>
                </h1>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: "#decdc3" }}
                >
                  Currently working as a full-time Software Developer, where I
                  mostly work on front-end side of things.
                </p>
              </div>
            </div>
          </Fade>
          <Experiences />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Experience;
