import React from "react";
import "./ImageCard.css";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

class ImageCard extends React.Component {
  render() {
    return (
      <div>
        <p className="browser-alert">
          This page looks best in browsers that support{" "}
          <a href="https://caniuse.com/#search=grid">CSS Grid</a> and
          <a href="http://caniuse.com/#search=object-fit">object fit</a>.
        </p>
        <section className="blog-post-teasers">
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/pokendexproject.jpg`)}
                alt="project name pokendex"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://github.com/originpulses/PokenDex");
                  }}
                >
                  {" "}
                  View PokenDex{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/pulseproject.jpg`)}
                alt="project name pulse"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://pulsechatapp-dfe55.web.app/");
                  }}
                >
                  {" "}
                  View Pulse{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/azulproject.jpg`)}
                alt="project name azul"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://github.com/originpulses/Azul");
                  }}
                >
                  {" "}
                  View Azul{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/humanoidproject.jpg`)}
                alt="project name humanoid rts"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab(
                      "https://github.com/originpulses/SEPM_Final_Project"
                    );
                  }}
                >
                  {" "}
                  View Project{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/clockplusproject.jpg`)}
                alt="project name clockplus"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://github.com/originpulses/ClockPlus");
                  }}
                >
                  {" "}
                  View ClockPlus{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/newsplusproject.jpg`)}
                alt="project name newsplus"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://radassignment2020.herokuapp.com/");
                  }}
                >
                  {" "}
                  View NEWS+{" "}
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/originpulsesproject.jpg`)}
                alt="project name personal photography website"
              />
              <figcaption>
                <h1
                  onClick={() => {
                    openInNewTab("https://www.originpulses.com/");
                  }}
                >
                  View Website
                </h1>
              </figcaption>
            </figure>
          </article>
          <article className="blog-post-teaser">
            <figure>
              <img
                src={require(`../../assests/images/voidalsproject.jpg`)}
                alt="project name voidals clothing brand"
              />
              <figcaption>
                <a> Voidals Clothing Brand Website {"\n"} (In Progress) </a>
              </figcaption>
            </figure>
          </article>
        </section>
      </div>
    );
  }
}

export default ImageCard;
