import React, { Component } from "react";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { degrees } from "../../portfolio";

class Educations extends Component {
  render() {
    return (
      <div>
        {degrees.degrees.map((degree) => {
          return <DegreeCard degree={degree} />;
        })}
      </div>
    );
  }
}

export default Educations;
