import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";

export default function Footer() {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: "#decdc3" }}>
          Copyright © 2024 Syed Sabih Ali -- Built with React
        </p>
      </Fade>
    </div>
  );
}
