import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { settings } from "../../portfolio.js";

const onMouseEnter = (event, textcolor) => {
  const el = event.target;
  el.style.color = textcolor;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.color = "#ea5455";
};

class Header extends Component {
  render() {
    const link = settings.isSplash ? "/splash" : "home";
    return (
      <Fade top duration={1000} distance="20px">
        <div>
          <header className="header">
            <NavLink to={link} tag={Link} className="logo">
              <span
                className="logo-name"
                style={{ color: "#ea5455" }}
                onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                onMouseOut={(event) => onMouseOut(event)}
              >
                S
              </span>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: "transparent" }}>
              <li className="btnfos-2">
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#ea5455" }}
                  onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Home
                </NavLink>
              </li>
              <li className="btnfos-2">
                <NavLink
                  to="/education"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#ea5455" }}
                  onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Education
                </NavLink>
              </li>
              <li className="btnfos-2">
                <NavLink
                  to="/experience"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#ea5455" }}
                  onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Experience
                </NavLink>
              </li>
              <li className="btnfos-2">
                <NavLink
                  to="/projects"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#ea5455" }}
                  onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Projects
                </NavLink>
              </li>
              <li className="btnfos-2">
                <NavLink
                  to="/contact"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#ea5455" }}
                  onMouseEnter={(event) => onMouseEnter(event, "#e5e5e5")}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Contact Me
                </NavLink>
              </li>
            </ul>
          </header>
        </div>
      </Fade>
    );
  }
}
export default Header;
