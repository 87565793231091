import React from "react";
import Main from "./containers/Main";
import { GlobalStyles } from "./global";
// import Maintenance from "./pages/Maintenance/Maintenance";

function App() {
  return (
    <>
      <GlobalStyles />
      <div>
        <Main />
      </div>
      {/* <Maintenance /> */}
    </>
  );
}

export default App;
