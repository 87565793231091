import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import { Fade } from "react-reveal";
import "../../RubberBand.css";
import $ from "jquery";

class Skills extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div className="main" id="skills">
        <div className="skills-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="skills-header" style={{ color: "#ea5455" }}>
              <span class="rubberBand">M</span>
              <span class="rubberBand">y</span>{" "}
              <span class="rubberBand">S</span>
              <span class="rubberBand">k</span>
              <span class="rubberBand">i</span>
              <span class="rubberBand">l</span>
              <span class="rubberBand">l</span>
              <span class="rubberBand">s</span>
            </h1>
          </Fade>
        </div>
        <SkillSection />
      </div>
    );
  }
}

export default Skills;
