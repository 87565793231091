// Website related settings
const settings = {
  isSplash: true,
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/originpulses",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/syedsabihali/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:sabih.bale96@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/itzsabih/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/originpulses/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

// Skills Section Data
const skills = {
  data: [
    {
      title: "Technology Stack",
      skills: [
        "I have accrued valuable experience by working with reputable companies, enabling me to adapt and apply various technology stacks effectively.",
      ],
      softwareSkills: [
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "devicon:typescript",
          style: {
            color: "#563D7C",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Jest",
          fontAwesomeClassname: "skill-icons:jest",
          style: {
            color: "#FF9A00",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "skill-icons:redux",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "logos:graphql",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "simple-icons:swift",
          style: {
            color: "#FA7343",
          },
        },
        {
          skillName: "HTML",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Amazon Web Services (AWS)",
          fontAwesomeClassname: "skill-icons:aws-light",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Jenkins CI/CD",
          fontAwesomeClassname: "skill-icons:jenkins-dark",
          style: {
            color: "#FF9A00",
          },
        },
        {
          skillName: "Splunk",
          fontAwesomeClassname: "simple-icons:splunk",
          style: {
            color: "#FF9A00",
          },
        },
        {
          skillName: "Ruby On Rails",
          fontAwesomeClassname: "simple-icons:rubyonrails",
          style: {
            color: "#CC0000",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "#007396",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00599C",
          },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#31A8FF",
          },
        },
      ],
    },
  ],
};

// Education Section Data
const degrees = {
  degrees: [
    {
      title: "RMIT University",
      subtitle: "Bachelor's Degree Information Technology",
      logo_path: "rmit.png",
      duration: "2016 - 2020",
    },
    {
      title: "Govt. Dehli College",
      subtitle: "Grade 12 Pre-Engineering, A+",
      logo_path: "dehlilogo.png",
      duration: "2013 - 2014",
    },
    {
      title: "BVS Parsi High School",
      subtitle: "Grade 10 Computer Science, A+",
      logo_path: "bvslogo.png",
      duration: "2002 - 2012",
    },
  ],
};

// Experience Section Data
const experience = {
  experience: [
    {
      title: "Leaders IT Services",
      subtitle: "Software Developer",
      logo_path: "leadersit.png",
      duration: "Feb, 2022 - Present",
    },
    {
      title: "National Australia Bank",
      subtitle: "Software Engineer (Contract)",
      logo_path: "nab.png",
      duration: "Feb, 2022 - Present",
    },
    {
      title: "ShipNow Information Technology",
      subtitle: "Software Developer",
      logo_path: "shipnow.png",
      duration: "Aug, 2021 - Jan, 2022",
    },
  ],
};

export { settings, socialMediaLinks, skills, degrees, experience };
