import React, { Component } from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import { experience } from "../../portfolio";

class Experiences extends Component {
  render() {
    return (
      <div>
        {experience.experience.map((experience) => {
          return <ExperienceCard experience={experience} />;
        })}
      </div>
    );
  }
}

export default Experiences;
