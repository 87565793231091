import React, { Component } from "react";
import "./DegreeCard.css";
import { Fade } from "react-reveal";

class DegreeCard extends Component {
  render() {
    const degree = this.props.degree;
    return (
      <Fade right duration={2000} distance="40px">
        <div className="degree-card">
          <div className="card-img">
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                transform: "scale(80%, 80%)",
              }}
              src={require(`../../assests/images/${degree.logo_path}`)}
              alt="school"
            />
          </div>
          <div className="card-body">
            <div className="body-header">
              <div className="body-header-title">
                <h2 className="card-title" style={{ color: "#decdc3" }}>
                  {degree.title}
                </h2>
                <h3 className="card-subtitle" style={{ color: "#decdc3" }}>
                  {degree.subtitle}
                </h3>
              </div>
              <div className="body-header-duration">
                <h3 className="duration" style={{ color: "#decdc3" }}>
                  {degree.duration}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default DegreeCard;
