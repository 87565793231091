import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import "../../RubberBand.css";
import $ from "jquery";
// import pdf from "../../assests/docs/resume.pdf";
import { withRouter } from "react-router-dom";

class Greeting extends React.Component {
  navigateToProjects = () => {
    this.props.history.push("/projects");
  };

  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <Fade bottom duration={2000} distance="40px">
        <div className="greet-main" id="greeting">
          <div className="greeting-main">
            <div className="greeting-text-div">
              <div>
                <h1 className="greeting-text" style={{ color: "#ea5455" }}>
                  <span class="rubberBand">H</span>
                  <span class="rubberBand">i</span>
                  <span class="rubberBand">,</span>{" "}
                  <span class="rubberBand">I</span>{" "}
                  <span class="rubberBand">a</span>
                  <span class="rubberBand">m</span>{" "}
                  <span class="rubberBand">S</span>
                  <span class="rubberBand">y</span>
                  <span class="rubberBand">e</span>
                  <span class="rubberBand">d</span>{" "}
                  <span class="rubberBand">S</span>
                  <span class="rubberBand">a</span>
                  <span class="rubberBand">b</span>
                  <span class="rubberBand">i</span>
                  <span class="rubberBand">h</span>{" "}
                  <span class="rubberBand">A</span>
                  <span class="rubberBand">l</span>
                  <span class="rubberBand">i</span>
                </h1>
                <p
                  className="greeting-text-p subTitle"
                  style={{ color: "#decdc3" }}
                >
                  <span class="rubberBand">A</span>{" "}
                  <span class="rubberBand">dedicated</span>{" "}
                  <span class="rubberBand">Software</span>{" "}
                  <span class="rubberBand">Engineer</span>{" "}
                  <span class="rubberBand">with</span>{" "}
                  <span class="rubberBand">over</span>{" "}
                  <span class="rubberBand">2 years</span>{" "}
                  <span class="rubberBand">of</span>{" "}
                  <span class="rubberBand">hands-on</span>{" "}
                  <span class="rubberBand">experience</span>{" "}
                  <span class="rubberBand">in</span>{" "}
                  <span class="rubberBand">software</span>{" "}
                  <span class="rubberBand">development</span>{" "}
                  <span class="rubberBand">and</span>{" "}
                  <span class="rubberBand">a</span>{" "}
                  <span class="rubberBand">background</span>{" "}
                  <span class="rubberBand">in</span>{" "}
                  <span class="rubberBand">RMIT University.</span>{" "}
                </p>
                <SocialMedia />
                <div className="portfolio-repo-btn-div">
                  <Button
                    text="SEE MY PROJECTS"
                    onClick={this.navigateToProjects}
                    className="portfolio-repo-btn"
                  />
                </div>
                {/* For downloading Resume */}
                {/* <Button
                    text="SEE MY RESUME"
                    newTab={true}
                    href={pdf}
                    className="portfolio-repo-btn"
                  /> */}
              </div>
            </div>
            <div className="landingpic-wrapper">
              <div className="greeting-image-div">
                <img
                  className="my-pic"
                  src={require(`../../assests/images/mypic.jpg`)}
                  alt="portrait of sabih"
                />
                <div className="square-shape"></div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}
export default withRouter(Greeting);
