import React, { Component } from "react";
import "./ExperienceCard.css";
import { Fade } from "react-reveal";

class ExperienceCard extends Component {
  render() {
    const experience = this.props.experience;
    return (
      <Fade right duration={2000} distance="40px">
        <div className="experience-card">
          <div className="card-img">
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                transform: "scale(80%, 80%)",
              }}
              src={require(`../../assests/images/${experience.logo_path}`)}
              alt="company"
            />
          </div>
          <div className="card-body">
            <div className="body-header">
              <div className="body-header-title">
                <h2 className="card-title" style={{ color: "#decdc3" }}>
                  {experience.title}
                </h2>
                <h3 className="card-subtitle" style={{ color: "#decdc3" }}>
                  {experience.subtitle}
                </h3>
              </div>
              <div className="body-header-duration">
                <h3 className="duration" style={{ color: "#decdc3" }}>
                  {experience.duration}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default ExperienceCard;
