import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./Projects.css";
import ImageCard from "./ImageCard";
import "../../RubberBand.css";
import $ from "jquery";

class Projects extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div className="projects-main">
        <Header />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: "#ea5455" }}
                >
                  <span class="rubberBand">P</span>
                  <span class="rubberBand">r</span>
                  <span class="rubberBand">o</span>
                  <span class="rubberBand">j</span>
                  <span class="rubberBand">e</span>
                  <span class="rubberBand">c</span>
                  <span class="rubberBand">t</span>
                  <span class="rubberBand">s</span>
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: "#decdc3" }}
                >
                  These are some of my university and personal projects I have
                  worked on. Aside from these I have worked on NAB's (National
                  Australia Bank) internal applications which can't be listed
                  here.
                </p>
              </div>
            </div>
            <ImageCard />
          </Fade>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Projects;
