import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import ContactForm from "./ContactForm";
import "../../RubberBand.css";
import $ from "jquery";
import "../../components/footer/Footer.css";

class Contact extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div className="contact-main">
        <Header />
        <div className="basic-contact">
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: "#ea5455" }}
                >
                  <span class="rubberBand">C</span>
                  <span class="rubberBand">o</span>
                  <span class="rubberBand">n</span>
                  <span class="rubberBand">t</span>
                  <span class="rubberBand">a</span>
                  <span class="rubberBand">c</span>
                  <span class="rubberBand">t</span>{" "}
                  <span class="rubberBand">M</span>
                  <span class="rubberBand">e</span>
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: "#decdc3" }}
                >
                  Please don't hesitate to contact me if there's an exciting
                  opportunity.
                </p>
                <p
                  className="contact-header-detail-icons"
                  style={{ color: "#decdc3" }}
                >
                  <i className="fas fa-map-marker-alt"></i> Melbourne, Australia
                  &nbsp;&nbsp;&nbsp; <br className="break-text" />{" "}
                  <i className="fas fa-envelope"></i> sabih.bale96@gmail.com
                </p>
              </div>
            </div>
            <ContactForm />
          </Fade>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
