import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Educations from "../../containers/education/Educations.js";
import "./EducationComponent.css";
import { Fade } from "react-reveal";
import "../../RubberBand.css";
import $ from "jquery";

class Education extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div className="education-main">
        <Header />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="education-heading-div">
              <div className="education-heading-text-div">
                <h1
                  className="education-heading-text"
                  style={{ color: "#ea5455" }}
                >
                  <span class="rubberBand">E</span>
                  <span class="rubberBand">d</span>
                  <span class="rubberBand">u</span>
                  <span class="rubberBand">c</span>
                  <span class="rubberBand">a</span>
                  <span class="rubberBand">t</span>
                  <span class="rubberBand">i</span>
                  <span class="rubberBand">o</span>
                  <span class="rubberBand">n</span>
                </h1>
                <p
                  className="education-header-detail-text subTitle"
                  style={{ color: "#decdc3" }}
                >
                  Throughout my educational journey, I have consistently
                  demonstrated a strong work ethic. I successfully earned a
                  Bachelor's degree in IT from RMIT University.
                </p>
              </div>
            </div>
          </Fade>
          <Educations />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Education;
