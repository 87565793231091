import React from "react";
import emailjs from '@emailjs/browser';
import "./ContactForm.css";

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
  nameError: "",
  emailError: "",
  subjectError: "",
  disabledButton: false,
};

class ContactForm extends React.Component {
  state = initialState;

  componentDidUpdate() {
    if (this.state.disabledButton !== false) {
      setTimeout(() => {
        this.setState({
          disabledButton: false,
        });
      }, 5000);
    }
  }

  validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  };

  validate = () => {
    let nameError = "";
    let emailError = "";
    let subjectError = "";

    if (!this.state.name) {
      nameError = "Required Field";
    }

    if (!this.state.subject) {
      subjectError = "Required Field";
    }

    if (!this.validateEmail(this.state.email)) {
      emailError = "Email is either invalid or is missing in the field";
    }

    if (emailError || nameError || subjectError) {
      this.setState({ emailError, nameError, subjectError });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  sendEmail = (e) => {
    e.preventDefault();

    const isValid = this.validate();

    if (isValid) {
      emailjs
        .sendForm(
          "service_hh8f6qo",
          "template_belomif",
          e.target,
          "Qo-80f4cjWqLq3HVM"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      this.setState(initialState);

      setTimeout(() => {
        this.setState({
          disabledButton: true,
        });
      }, 4);
    }
  };

  render() {
    const nameError = this.state.nameError;
    const emailError = this.state.emailError;
    const subjectError = this.state.subjectError;

    return (
      <form onSubmit={this.sendEmail}>
        <div className="form-group">
          {nameError === "" ? (
            <i></i>
          ) : (
            <i class="error-icon fas fa-exclamation-circle"></i>
          )}
          <input
            name="name"
            type="text"
            className="feedback-input"
            placeholder="Enter Name"
            value={this.state.name}
            onChange={this.handleChange}
          />
          <div className="line"></div>
          <div className="error-message">{this.state.nameError}</div>
        </div>
        <div className="form-group">
          {emailError === "" ? (
            <i></i>
          ) : (
            <i class="error-icon fas fa-exclamation-circle"></i>
          )}
          <input
            name="email"
            type="text"
            className="feedback-input"
            placeholder="Enter Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <div className="line"></div>
          <div className="error-message">{this.state.emailError}</div>
        </div>
        <div className="form-group">
          {subjectError === "" ? (
            <i></i>
          ) : (
            <i class="error-icon fas fa-exclamation-circle"></i>
          )}
          <input
            name="subject"
            type="text"
            className="feedback-input"
            placeholder="Subject"
            value={this.state.subject}
            onChange={this.handleChange}
          />
          <div className="line"></div>
          <div className="error-message">{this.state.subjectError}</div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            className="feedback-input"
            placeholder="Your Message"
            value={this.state.message}
            onChange={this.handleChange}
          />
          <div className="line"></div>
        </div>
        <input
          type="submit"
          value={this.state.disabledButton ? "Message Sent" : "Send Message"}
          defaultValue={
            this.state.disabledButton ? "Message Sent" : "Send Message"
          }
          disabled={this.state.disabledButton}
        />
      </form>
    );
  }
}

export default ContactForm;
