import React from "react";
import "./Button.css";

export default function Button({ text, className, onClick, newTab }) {
  return (
    <div className={className}>
      <a class="main-button" onClick={onClick} target={newTab && "_blank"}>
        {text}
      </a>
    </div>
  );
}

// For downloading Resume Button
// export default function Button({ text, className, href, newTab }) {
//   return (
//     <div className={className}>
//       <a class="main-button" href={href} target={newTab && "_blank"}>
//         {text}
//       </a>
//     </div>
//   );
// }
