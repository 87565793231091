import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import LaptopAnimation from "./LaptopAnimation";
import "../../RubberBand.css";
import $ from "jquery";

class SkillSection extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(".rubberBand").bind(
        "webkitAnimationEnd mozAnimationEnd animationend",
        function () {
          $(this).removeClass("animated");
        }
      );

      $(".rubberBand").hover(function () {
        $(this).addClass("animated");
      });
    });
  }

  render() {
    return (
      <div>
        {skills.data.map((skill) => {
          return (
            <div className="skills-main-div">
              <Fade left duration={2000}>
                <div className="skills-image-div">
                  <LaptopAnimation />
                </div>
              </Fade>

              <div className="skills-text-div">
                <Fade right duration={1000}>
                  <h1 className="skills-heading" style={{ color: "#ea5455" }}>
                    <span class="rubberBand">Technology</span>{" "}
                    <span class="rubberBand">Stack</span>
                  </h1>
                </Fade>
                <Fade right duration={1500}>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade right duration={2000}>
                  <div>
                    {skill.skills.map((skillSentence) => {
                      return (
                        <p
                          className="subTitle skills-text"
                          style={{ color: "#decdc3" }}
                        >
                          {skillSentence}
                        </p>
                      );
                    })}
                  </div>
                  <p
                    className="subTitle skills-text"
                    style={{ color: "#decdc3" }}
                  >
                    View my Certified AWS Solutions Architect accreditation{" "}
                    <span>
                      <a
                        href="https://www.credly.com/badges/c15303d4-9eaa-446f-b4e9-ecc4504f6262"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "rgb(234, 84, 85)",
                          textDecoration: "underline",
                        }}
                      >
                        here
                      </a>
                    </span>
                  </p>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
